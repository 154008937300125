const {
  guestId,
  origin,
  redirectLink,
  baseUri,
  webSocketUri,
  columnsNumber,
  internal_host: internalHost,
} = window.options;

const isUserPaid = () => ({
  isPaid: window.user && window.user.isPaid || false,
});

const getUserEmail = () => ({
  internalEmail: window.user && window.user.internalEmail || '',
});

const isUserLogged = () => ({
  isLogged: window.user && window.user.isLogged || false,
});

const getRedirectLink = (response) => {
  const data = JSON.parse(response);
  if (data.result) {
    // document.cookie = `api_js=${data.data.api_js}; path=/; max-age=3600`;
    window.location.href = `${data.data.redirect_url}${internalHost}`;
  }
};

const setExternalGuestId = (url, callback, uploadData) => {
  const ajax = new XMLHttpRequest();
  const body = {
    guestId,
  };

  ajax.open('POST', url, true);
  ajax.setRequestHeader('userid', uploadData.auth.userId);
  ajax.setRequestHeader('token', uploadData.auth.token);
  ajax.setRequestHeader('appkey', uploadData.auth.appKey);
  ajax.setRequestHeader('Content-Type', 'application/json');
  ajax.onload = function () {
    if (!ajax.response) {
      setExternalGuestId(`${baseUri}/api_v3/abTest/setExternalGuestId`, getRedirectLink, uploadData);
    }
  };
  ajax.send(JSON.stringify(body));
};

const openEditor = (res) => {
  const { type, feature, uploadData } = res;

  if (type) {
    setExternalGuestId(`${baseUri}/api_v3/abTest/setExternalGuestId`, getRedirectLink, res.uploadData);
    const data = {
      body: {
        origin,
        projectId: uploadData.docList[0].id,
        guestId,
      },
      headers: {
        userId: uploadData.auth.userId,
        token: uploadData.auth.token,
        appKey: uploadData.auth.appKey,
      },
    };
    $.ajax({
      type: 'POST',
      url: redirectLink,
      data,
      success: (json) => {
        const eventData = { event: 'click' };
        const request = new XMLHttpRequest();
        request.open('POST', '/click-event/click', true);
        request.setRequestHeader('Content-Type', 'application/json');
        const parceJson = JSON.parse(json);

        request.onload = function () {
          if (request.readyState === 4 && parceJson.result) {
            // document.cookie = `api_js=${parceJson.data.api_js}; path=/; max-age=3600`;
            window.location.href = `${parceJson.data.redirect_url}${internalHost}`;
          }
        };

        request.send(JSON.stringify({ data: eventData }));
      },
    }).fail((xhr, status, error) => {
      console.log(error);
    });
  }
  return true;
};

const runTemplateSuggestion = () => {
  console.log('runTemplateSuggestion');
  return true;
};

const updateFolders = () => {
  console.log('updateFolders');
  return true;
};

const sendStat = () => {
  console.log('sendStat');
  return true;
};

const releaseUploaderController = () => {
  console.log('releaseUploaderController');
  return true;
};

const captureController = () => {
  console.log('captureController');
  return true;
};

const isLoggedIn = () => {
  console.log('isLoggedIn');
  return true;
};

const renderUploader = () => {
  if (window.UPLOADER === undefined) return;

  window.UPLOADER({
    dashboardSelector: '.root',
    app: 'MICROSITE',
    modes: {
      microSites: true,
    },
    features: [
      '@UPLOADER/UPLOAD',
      '@UPLOADER/GOOGLE_DRIVE',
      '@UPLOADER/DROPBOX',
      '@UPLOADER/ONE_DRIVE',
      '@UPLOADER/BOX',
      '@UPLOADER/GET_FROM_URL',
      '@UPLOADER/SEARCH_DOCUMENT',
      '@UPLOADER/GET_FROM_EMAIL',
      '@UPLOADER/REQUEST_DOCUMENT',
    ],
    appearance: {
      dashboard: {
        columns: columnsNumber,
        columnsTablet: 3,
      },
    },
    api: {
      base: baseUri,
      ws: {
        host: webSocketUri,
        port: '443',
      },
    },
    auth: {
      appKey: 'vMicrosites_v1_0',
    },
    multiple: 8,
    hooks: {
      data: {
        modalOpen: [getUserEmail, isUserLogged, isUserPaid],
      },
      stat: sendStat,
    },
    callbacks: {
      end: openEditor,
      request: runTemplateSuggestion,
    },
  });
};

window.addEventListener('DOMContentLoaded', renderUploader);
